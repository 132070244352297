.tooltip-container {
    position: relative;
    display: inline-block;
    padding-bottom: 8px;
}

.tooltip {
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px;
    background-color: black;
    color: white;
    border-radius: 5px;
    font-size: 14px;
}

.tooltip-text {
    margin-bottom: 5px;
    z-index: 1;
}

.tooltip-arrow {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid black;
}