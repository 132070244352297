* p {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.main-nav {
  display: flex;
  justify-content: space-between;
  background-color: #11101d;
  font-size: 25px;
  text-decoration: none;
}

.main-nav a {
  margin: 15px 15px 15px 15px;
  text-decoration: none;
  color: white;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  background-color: transparent;
  border: 0;
}

.main-nav .user-nav {
  margin: 15px 15px 15px 15px;
  text-decoration: none;
  color: white;
  font-family: "Segoe UI", Tahoma, Verdana, sans-serif;
  background-color: transparent;
  border: 0;
}

.main-nav .user-nav .user_btn {
  display: flex;
  flex-direction: row;
  font-size: 20px;
}

.main-nav .user-nav .user_btn i {
  margin: 6px 10px 0px 10px;
  font-size: 13px;
}

.main-nav .user-nav .logout_menu {
  display: flex;
  flex-direction: row;
  font-size: 15px;
}

.main-nav .user-nav .logout_menu p {
  background-color: transparent;
  border: 0;
  color: white;
}

.main-nav .user-nav .logout_menu i {
  margin-right: 10px;
}

.main-contenedor {
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 80%;
  margin: 20px auto;
  background-color: #f0efef;
  border-radius: 5px;
  min-width: 320px;
  max-width: 80%;
  min-height: 300px;
  box-shadow: 5px 10px 10px rgb(158, 158, 158);
  color: #1d1d1d;
}

.main-contenedor .header-container {
  margin-top: 15px;
}

.main-contenedor .container-child {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  margin: 0px 10px 0px 10px;
}

.main-contenedor .container-child .tarjetas-campo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  max-height: 150px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 2px 5px 5px rgb(158, 158, 158);
  cursor: pointer;
  margin-bottom: 10px;
  transition: all 0.2s ease;
  border: 0;
}

.main-contenedor .container-child .tarjetas-campo i {
  font-size: 30px;
}

.main-contenedor .container-child .tarjetas-campo p {
  font-size: 20px;
}

.main-contenedor .container-child .tarjetas-campo:hover {
  box-shadow: 1px 3px 3px rgb(158, 158, 158);
}

.main-footer {
  background-color: #141e27;
  height: 100%;
}
