.formulario_cultivo {
  width: 70%;
  margin: 50px auto;
}

.container .btn.btn-primary.button-label-margin,
.btn.btn-success.button-label-margin,
.btn.btn-outline-success.button-label-margin {
  margin-top: 25px;
}

.formulario_cultivo button {
  float: right;
}

.formulario_cultivo .form-select {
  display: block;
}

.formulario_cultivo .form-lote {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div .form-lote {
  vertical-align: bottom;
}

div .form-lote .button-lote {
  margin-bottom: 0;
}

.formulario_cultivo .btn {
  float: none;
  margin-right: 15px;
}

.formulario_cultivo .variedad_form_container {
  display: flex;
  justify-content: space-around;
}

.formulario_cultivo .add_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.formulario_cultivo .add_container button {
  display: flex;
  margin-left: 25px;
}

.formulario_cultivo h5 i {
  font-size: 15px;
  margin-right: 10px;
}

.formulario_cultivo h5 {
  display: flex;
  align-items: center;
}

.formulario_cultivo .input__group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.formulario_cultivo .input__group .size-input {
  width: 250px;
  font-size: 1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.formulario_cultivo .variedad_form_container .variedad_container button {
  margin-top: 15px;
  margin-left: 10px;
}

.formulario_cultivo table tbody tr td button {
  float: none;
  margin-left: 25px;
}

.formulario_cultivo table tbody tr td .button-trash {
  background-color: red;
  text-align: center;
  align-items: center;
  border-radius: 5px;
}

.formulario_cultivo table tbody tr td .button-edit {
  background-color: rgb(60, 93, 143);
  border-radius: 5px;
}

.formulario_cultivo table tbody tr td button,
.edit {
  color: white;
  text-align: center;
}

/*--------------------------------Configuracion etiquetas--------------------------------*/

.form-container-etiquetas {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-container-etiquetas select {
  margin-bottom: 25px;
}

.etiquetas-container {
  max-height: 450px;
  width: 100%;
  border: 1px solid;
  overflow-y: scroll;
}

.form-check {
  margin-left: 20px;
}

/*----------------------------------Configuracioin palet----------------------------------*/
.palet-main-container {
  margin-top: 71px;
}
.palet-table-container {
  width: 40%;
}

.palet-main-container .palet-form-container {
  max-width: 80%;
  margin: 25px auto;
}

.palet-main-container .button-palet {
  display: flex;
  align-items: flex-end;
}

.palet-main-container .button-palet button {
  margin-bottom: 0px;
  margin-left: 15px;
}

.palet-table-container .table tbody tr td button {
  margin-left: 15px;
  margin-bottom: 0;
}

.palet-table-container .table {
  margin-top: 25px;
}
