.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.form-container {
  display: flex;
  flex-direction: column;
  background-color: #f8f8fa;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  border-radius: 1rem;
  gap: 1.5rem;
  width: 32rem;
  min-height: 20rem;
}
.logo {
  height: 94px;
  width: 94px;
}
